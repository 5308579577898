<script setup>
import InnerImageZoom from 'vue-inner-image-zoom';
import { useCarouselStore } from '../stores/carousel';

const store = useCarouselStore();
const { setActiveItem, setGalleryItems } = store;

const overlay = computed(() => store.activeItem);
const galleryItems = computed(() => store.galleryItems);
const footer = ref(null);

// watch(overlay, () => {
//   window.removeEventListener('scroll', closeOverlay);
//   if (overlay.value != null) {
//     window.addEventListener('scroll', closeOverlay);
//   }
// });

onMounted(() => {
  footer.value = document.querySelector('.footer');
});

onUnmounted(() => {
  // window.removeEventListener('scroll', closeOverlay);
  setActiveItem(null);
  setGalleryItems([]);
});

const closeOverlay = () => {
  setActiveItem(null);
  footer.value != null && footer.value.classList.remove('z--hide');
};

const handleIframeLoad = (event) => {
  event.target.style.opacity = '1';
};
</script>

<template>
  <div :class="{ invisible: overlay === null }" class="overlay">
    <div class="overlay--nav">
      <p>{{ `${overlay + 1} / ${galleryItems.length} ` }}</p>
      <button class="pswp__button--close pswp__button" title="Close (Esc)" @click="closeOverlay()" @keyup.esc="closeOverlay()"><i class="icon-cross" /></button>
    </div>

    <div v-if="overlay !== null && galleryItems[overlay].type == 'embed'" class="showcased showcased--video">
      <div class="showcased--video__holder">
        <div class="showcased--video__inner">
          <iframe
            :key="overlay"
            width="100%"
            height="100%"
            :src="galleryItems[overlay].embed"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-scripts allow-same-origin allow-presentation"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; opacity: 0; transition: opacity 0.3s"
            @load="handleIframeLoad"
          />
        </div>
      </div>
    </div>

    <div v-else-if="overlay != null" class="overlay--pic__container">
      <InnerImageZoom
        class="overlay--pic__inner"
        :src="useGetImageUrl(galleryItems[overlay].thumbnail, 1000) || useGetImageUrl(galleryItems[overlay].url, 1000)"
        :zoomSrc="galleryItems[overlay].thumbnail || galleryItems[overlay].url"
        :alt="galleryItems[overlay]?.caption"
      />
    </div>

    <div class="arrows" v-if="galleryItems.length > 1">
      <button
        class="pswp__button pswp__button--arrow--left"
        title="Previous (arrow left)"
        @click="setActiveItem(overlay === 0 ? galleryItems.length - 1 : overlay - 1)"
        @keyup.left="setActiveItem(overlay === 0 ? galleryItems.length - 1 : overlay - 1)"
      >
        <i class="icon-arrow-left" />
      </button>
      <button
        class="pswp__button pswp__button--arrow--right"
        title="Next (arrow right)"
        @click="setActiveItem(overlay === galleryItems.length - 1 ? 0 : overlay + 1)"
        @keyup.right="setActiveItem(overlay === galleryItems.length - 1 ? 0 : overlay + 1)"
      >
        <i class="icon-arrow-right" />
      </button>
    </div>

    <p v-if="overlay != null" class="overlay--caption">
      {{ typeof galleryItems[overlay].caption === 'string' && galleryItems[overlay].caption.trim().length > 0 ? galleryItems[overlay].caption : '' }}
    </p>
  </div>
</template>

<style lang="scss">
@use 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

.overlay {
  background-color: white;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition-duration: 500ms;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 6;
  padding: 1rem !important;
}
.invisible {
  visibility: hidden;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.pswp__button {
  border: none;
  cursor: pointer;
  background-color: white !important;
}
.pswp__button--arrow--left,
.pswp__button--arrow--right {
  z-index: 6;
  height: 70px;
  padding-top: 8px;
  width: 48px;
  margin: -16px;
}
.pswp__button--close {
  color: black;
}
.arrows {
  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.overlay--nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
  padding: 16px;
}
.overlay--caption {
  position: absolute;
  bottom: 1rem;

  text-align: center;
  padding: 16px;
  width: 100%;
  @media (max-width: 900px) {
    font-size: 12px;
    height: 26px;
  }
}

.showcased {
  transition-duration: 500ms;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-top: 54px;
  padding-bottom: 54px;

  @media (min-width: 1200px) {
    padding: 60px;
  }
}

.showcased--video__inner {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height:width) */
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.showcased--video__holder {
  max-width: 400px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (min-width: 670px) {
    max-width: 598px;
    max-height: 300px;
  }

  @media (min-width: 1024px) {
    max-width: 1000px;
    max-height: 550px;
  }
}

.showcased--video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.overlay--pic__container {
  position: absolute;
  bottom: 3rem;
  top: 3rem;

  display: flex !important;
  align-items: center;
  justify-content: center;
}
.overlay--pic__inner {
  display: flex !important;
  align-items: center;
  justify-content: center;
  picture {
    img {
      height: 100%;
      max-height: 75vh !important;
      width: 100%;
      max-width: 95vw;
    }
  }
}
</style>
